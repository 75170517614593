import type { ReactNode } from 'react';
import Link from 'next/link';
import { NavHamburger } from '@/components/Layouts';
import styles from './index.module.less';
import { AppSettings } from '@/shared/app-common';
// import ChangeLanguage from '@/components/Common/Language';
import UserContent from '../UserContent';

const MobileHeader = ({ children }: { children?: ReactNode[] }) => {
  return (
    <div className={styles.mobileHeaderWarp}>
      <div className={styles.navToolbar}>
        <div className={styles.navToolbarSection}>
          <NavHamburger>{children}</NavHamburger>
        </div>

        <div className={styles.navToolbarSection}>
          <Link href="/" className={styles.logo}>
            <img
              className={styles.pietra}
              src={AppSettings.logoPath}
              alt={AppSettings.name}
              title={AppSettings.name}
              loading="lazy"
              width={32}
              height={32}
            />
            <img
              src={AppSettings.namePath}
              alt={AppSettings.name}
              style={{ height: '28px', width: '117px', paddingLeft: '5px' }}
              loading="lazy"
            />
          </Link>
        </div>

        <div className={styles.navToolbarSection}>
          {/* <NavMobileSearch className={styles.searchIcon} /> */}
          {/* <ChangeLanguage /> */}

          <div className={styles.userContent}>
            <UserContent />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileHeader;
