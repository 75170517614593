import { CloseOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React from 'react';
import styles from './index.module.less';

const PopoverInfo = ({
  desc,
  open,
  setOpen,
  children,
  ...props
}: {
  desc: React.ReactNode;
  open: boolean;
  setOpen: () => void;
  children: React.ReactNode;
}) => {
  return (
    <Popover
      content={
        <div className={styles.popoverInfo}>
          <span>{desc}</span>
          <CloseOutlined className={styles.closeIcon} onClick={setOpen} />
        </div>
      }
      open={open}
      placement="bottomRight"
      {...props}
    >
      {children}
    </Popover>
  );
};

export default PopoverInfo;
