import { KeyboardEvent, useCallback, useState } from 'react';
import { Drawer, Input } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useConfigProvider } from '@/context/ConfigProvider';
import { useToggle } from 'react-use';
import { useRouter } from 'next/router';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';

const NavMobileSearch = ({ className }: { className?: string }) => {
  const { isMobile } = useConfigProvider();
  const router = useRouter();
  const [open, toggle] = useToggle(false);
  const [searchValue, setSearchValue] = useState<string>();
  const { t } = useTranslation('common');

  const handleSearch = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation();

      if (searchValue != undefined) {
        toggle(false);
        router.push(`/search?value=${searchValue}`);
      }
      // router.push(
      //   shopAllLink(searchValue, {
      //     text: searchValue,
      //     scrollToTop: true,
      //   }),
      // );
    },
    [router, searchValue],
  );

  return (
    <div className={classNames(styles.navMobileSearchWarp, className)}>
      <SearchOutlined onClick={toggle} style={{ color: `var(--app-text-color)`, fontSize: 20 }} />
      <Drawer
        style={{ background: `var(--foreground-rgb)` }}
        closeIcon={<CloseOutlined style={{ color: `var(--app-text-color)` }} />}
        title={false}
        closable={true}
        width={isMobile ? '100%' : 400}
        height={'100vh'}
        placement="top"
        onClose={toggle}
        open={open}
        rootClassName={styles.navSearchDrawerWarp}
        key={'left-menu-drawer'}
      >
        <div className={styles.searchWarp}>
          <input
            className={styles.shopSearchField}
            placeholder={t('search')}
            onChange={(e) => setSearchValue(e.target.value)}
            // onPressEnter={handleSearch}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                handleSearch(e);
              }
            }}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default NavMobileSearch;
