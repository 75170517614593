import Link from 'next/link';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import styles from './index.module.less';
import RetroTitle from '@/components/Common/RetroTitle';
import type { MarketplaceNavItem } from '@/components/Layouts';
import { NavItem, MobileHeader } from '@/components/Layouts';
import { AppSettings } from '@/shared/app-common';
import Image from 'next/image';
import dynamic from 'next/dynamic';
// import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';

const UserContent = dynamic(() => import('../UserContent'));

interface GlobalHeaderProps {
  isMarketplaceHome?: boolean;
  navItems?: MarketplaceNavItem[];
  isMobile?: boolean;
}

const GlobalHeader = ({ navItems, isMarketplaceHome, isMobile }: GlobalHeaderProps) => {
  // const router = useRouter();
  const { isLogin, onOpenLoginModal, onOpenCreateReportAlert, onGetStartedClick } =
    useUserProvider();
  const { t } = useTranslation('common');

  const UnloginHeader = () => {
    return (
      <div className={styles.unLogin}>
        <span
          onClick={() => {
            onGetStartedClick('none');
            onOpenLoginModal(true);
          }}
        >
          {t('Join Now')}
        </span>
        {` `}
        <span>{t('to get your customized Preparedness Guide')}</span>
      </div>
    );
  };

  const getNavLeftSection = useMemo(() => {
    return (
      <div className={styles.navTitleContainer}>
        <div className={styles.catchPhrase}>
          <Link href="/">
            <RetroTitle
              text={
                <div style={{ height: '32px' }}>
                  <Image
                    className={styles.logo}
                    src={AppSettings.logoPath}
                    alt={AppSettings.name}
                    loading="lazy"
                    width={32}
                    height={53}
                  />
                  <img
                    src={AppSettings.namePath}
                    alt={AppSettings.name}
                    style={{ height: '28px', marginLeft: '5px' }}
                  />
                  <Image
                    src={'/images/layouts/collect-icon.png'}
                    alt="collect"
                    width={32}
                    height={32}
                  />
                </div>
              }
              // textColor="#ff401a"
              fontSize={28}
              showAlert={true}
            />
          </Link>
        </div>
      </div>
    );
  }, [isMarketplaceHome]);

  const formatMenuData = useCallback(
    (navItems?: MarketplaceNavItem[]): MarketplaceNavItem[] => {
      if (isMobile) {
        return navItems?.reduce(
          (acc: MarketplaceNavItem[], cur: MarketplaceNavItem) => {
            return cur?.dropdownItems
              ? [...acc, { text: cur.text, path: cur.path }, ...cur?.dropdownItems]
              : [...acc, cur];
          },
          [
            // {
            //   text: 'Home',
            //   path: '/',
            // },
          ],
        );
      }
      return navItems;
    },
    [isMobile],
  );

  const renderNavRightSection = useMemo(() => {
    return formatMenuData(navItems)?.map((item, index) => (
      <NavItem
        item={item}
        key={index}
        className={classNames('', {
          'mobile-left-menu-item': isMobile,
        })}
      />
    ));
  }, [formatMenuData, isMobile, navItems]);
  // if (isMobile || router.pathname === '/hair-change') {
  if (isMobile) {
    return <MobileHeader>{renderNavRightSection}</MobileHeader>;
  }
  return (
    <div className={styles.headerContainer}>
      {!isLogin && <UnloginHeader />}
      <div className={classNames(styles.globalHeaderWarp)}>
        <div className={styles.navSection}>{getNavLeftSection}</div>

        <div className={styles.navSection}>{renderNavRightSection}</div>

        <div className={styles.navSection}>
          {/* <div style={{ marginRight: '42px' }}>
          <ChangeLanguage />
        </div> */}

          <UserContent />
        </div>
      </div>
    </div>
  );
};

export default GlobalHeader;
