import React from 'react';
import styles from './index.module.less';
import {
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import copy from 'clipboard-copy';
import { App, Modal } from 'antd';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

const ContentShareAlert = ({
  openType,
  // handleOk,
  handleCancel,
  description,
  shareUrl,
  shareImage,
}: {
  openType?: any;
  // handleOk?: (value: any) => void,
  handleCancel?: () => void;
  description?: string;
  shareUrl?: string;
  shareImage?: string;
}) => {
  const { message } = App.useApp();

  const { t } = useTranslation('common');
  const xPath = '/images/share/share_twitter.png';
  const fbPath = '/images/share/share_fb.png';
  const whatsPath = '/images/share/share-whats-icon.png';
  const copyPath = '/images/share/share_copy_blank.png';
  const redditPath = '/images/share/share-reddit-icon.webp';

  const handleCopyAction = async () => {
    try {
      await copy(shareUrl);
      message.success(t('URLCopiedToClipboard'));
    } catch (err) {
      console.error('Unable to copy text to clipboard:', err);
    }
  };

  return (
    <Modal
      // width={300}
      open={openType}
      // onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      maskClosable={false}
    >
      <div className={styles.container}>
        <div className={styles.shareTitle}>
          <span>{`${t('share')}:`}</span>
        </div>

        <div className={styles.copyContainer}>
          <span className={styles.url}>{`${shareUrl}`}</span>

          <Image
            className={styles.copy}
            src={copyPath}
            alt="Copy"
            loading="lazy"
            width={30}
            height={30}
            // layout="responsive"
            onClick={handleCopyAction}
          />
        </div>

        <div className={styles.shareContainer}>
          <WhatsappShareButton style={{ marginRight: '10px' }} url={shareUrl} title={description}>
            <Image src={whatsPath} alt="Whats" loading="lazy" width={30} height={30} />
          </WhatsappShareButton>

          <RedditShareButton
            style={{ marginRight: '10px' }}
            url={shareUrl}
            title={description + shareUrl}
          >
            <Image src={redditPath} alt="Facebook" loading="lazy" width={30} height={30} />
          </RedditShareButton>

          <FacebookShareButton
            style={{ marginRight: '10px' }}
            url={shareUrl}
            title={description + shareUrl}
          >
            <Image src={fbPath} alt="Facebook" loading="lazy" width={30} height={30} />
          </FacebookShareButton>

          <TwitterShareButton style={{ marginRight: '10px' }} title={description} url={shareUrl}>
            <Image src={xPath} alt="Twitter" loading="lazy" width={30} height={30} />
          </TwitterShareButton>
        </div>
      </div>
    </Modal>
  );
};

export default ContentShareAlert;
